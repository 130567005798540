<template>
  <div>
    <CRow>      
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de permisos"
          icon="fas fa-user-times"
          btn_name="permiso"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_action_mybutton="mtd_action_mybutton"           
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre de permiso"
            v-model="permision.name"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            label="Descripcion"
            placeholder="Digite Decripción de permiso"
            v-model="permision.description"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Permiso"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>


<script>

const fields = [
  { key: 'Id', _style:'width:3%' },
  { key: 'Nombre', _style:'width:20%;' },
  { key: 'Descripcion', _style:'width:20%;' },  
  // { 
  //   key: 'show_details', 
  //   label: 'Detalle', 
  //   _style: 'min-width:1%'
  // },
]

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from '../../main'

export default {
  components: { CTableWrapper, CModalForm,cModalDelete },
  data() {
    return {
      prefix: "permission",
      fields,
      data: [],
      myButtons:[
        // {
        //   class:'btn-sm',
        //   color:'success',
        //   tooltip:'prueba',
        //   action:'mtd_action',
        //   icon:'fas fa-trash',
        //   // play: {
        //   //   type: "type_id_add_alternativas", //resource
        //   //   1: true,
        //   //   2: false,
        //   // },
        // },
        // },
      ],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      permision: {
        id: "",
        name: "",
        description: "",
        status: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_button: function () {
      if (this.permision.name != "" && this.permision.description) return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/permission",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.map((item, order) => { return {...item, order}});
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo permiso")
        : (this.modal.title = "Editar permiso");
      action == "store" 
        ? (
            this.permision= {
              id: "",
              name: "",
              description: "",
              status: "",
            }
          )
        : this.permision = this.permision;
    },
    mtd_action: function () {
      alert('nsn')
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.permision,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
             if(response[0].Id == 1){
              this.mtd_getdata();
            }else{
              this.data.push(response[0]);
            }
            message = "REGISTRADO CORRECTAMENTE";
          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.Nombre = response[0].Nombre;
                element.Descripcion = response[0].Descripcion;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
            description: "",
          };
          bus.$emit('alert', { 
            color:color,
            message:message
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
          bus.$emit('alert', { 
            color:'danger',
            message:errors
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.permision = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete= {
            boo: false,
            item: [],
          };
          bus.$emit('alert', { 
            color:'success',
            message:'ELIMINADO CORRECTAMENTE'
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /*** actions mybuttons */
    mtd_action_mybutton: function(item){
      /** aqui accion de cada myboton */
    }
  },
};
</script>

<style scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>